import React, { useCallback, useEffect, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Button from "../components/form/button"
import Head from "../components/header/head"
import Layout from "../components/layout"
import {
  HEADER_COLOR_SUHBAH,
  HEADER_IMAGE_SUHBAH,
  HEADER_PAGE_NAME_SUHBAH,
  HEADER_SUB_TITLE_SUHBAH,
  HEADER_TITLE_SUHBAH,
  MAX_FEATURED_POSTS,
} from "../utils/constants"
import Slider from "../components/slider/slider"
import Heading from "../components/form/heading"
import {
  encodeURL,
  getSearchQueryFromUrl,
  getSearchResults,
  getSearchUrl,
  hasFilters,
  isBrowser,
  removeDuplicates,
} from "../utils/utils"
import SearchResult from "../components/search/search-result"
import type { Post } from "../common/post.model"
import type { SearchQueryOptions } from "../components/search/search-query-options.model"
import SliderHeader from "../components/slider/slider-header"

const SuhbahsPage = ({ location }) => {
  const suhbahsData = useStaticQuery(graphql`
    query {
      allSuhbahs: allContentfulPost(
        sort: { fields: publishedDate, order: DESC }
        filter: { postType: { typeId: { eq: "SUHBAH" } } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            featured
            tags
            originalAuthor {
              name
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      featuredSuhbahs: allContentfulPost(
        sort: { fields: updatedAt, order: DESC }
        filter: { postType: { typeId: { eq: "SUHBAH" } }, featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
    }
  `)
  // test
  // reorganize query data
  const suhbahs: Post[] = suhbahsData.allSuhbahs.edges.map((ad: any) => ad.node)
  const recentSuhbahs = suhbahs.slice(0, MAX_FEATURED_POSTS)
  const featuredSuhbahs = suhbahsData.featuredSuhbahs.edges.map((edge: any) => edge.node)
  // extract categories and authors lists
  const categories = removeDuplicates(suhbahs.map(p => !!p.category && p.category.name))
  const originalAuthorsNames = removeDuplicates(suhbahs.map(p => !!p.originalAuthor && p.originalAuthor.name))
  // state
  const [filteredResults, setFilteredResults] = useState<Post[]>([])
  const [isCurrentlySearching, setIsCurrentlySearching] = useState<boolean>(false)

  useEffect(() => {
    const query = getSearchQueryFromUrl(location.search)
    if (!query || (!query.all && !query.searchQuery)) {
      setFilteredResults([])
      setIsCurrentlySearching(false)
    } else if (query.all === "featured") {
      setFilteredResults(featuredSuhbahs)
      setIsCurrentlySearching(true)
    } else if (query.all === "recent") {
      setFilteredResults(suhbahs)
      setIsCurrentlySearching(true)
      if (isBrowser) {
        window.scrollTo(0, 0)
      }
    } else if (query.searchQuery) {
      setFilteredResults(getSearchResults(suhbahs, query.searchQuery))
      setIsCurrentlySearching(true)
    }
  }, [location])

  const onSearch = (searchQuery: SearchQueryOptions) => {
    if (hasFilters(searchQuery)) {
      navigate(encodeURL(getSearchUrl(searchQuery)))
    } else {
      navigate("/suhbah")
    }
  }
  const handleAllRecent = () => {
    navigate("/suhbah?all=recent")
  }
  const handleAllFeatured = () => {
    navigate("?all=featured")
  }
  const onClearSearch = () => {
    navigate("/suhbah")
  }

  const headerSearchOptions = {
    categories: categories,
    originalAuthorsNames: originalAuthorsNames,
    authorsTitle: "PAR MURSHID",
    onSearch: onSearch,
  }
  return (
    <Layout
      headerImg={HEADER_IMAGE_SUHBAH}
      headerPageName={HEADER_PAGE_NAME_SUHBAH}
      headerSearch={headerSearchOptions}
      headerTitle={HEADER_TITLE_SUHBAH}
      headerSubTitle={HEADER_SUB_TITLE_SUHBAH}
      headerColor={HEADER_COLOR_SUHBAH}
    >
      <Head
        title="Ṣuḥbah"
        description="Enseignements des Murshid, Maîtres spirituels-éducateurs, de la Voie soufie Naqshbandi."
      />
      {!isCurrentlySearching ? (
        <div className="py-8">
          {featuredSuhbahs?.length ? (
            <div>
              <SliderHeader
                text="Les ṣuḥbah à la une :"
                buttonText="Toutes les ṣuḥbah à la une"
                color="yellow"
                onSeeMoreClick={handleAllFeatured}
              ></SliderHeader>
              <div className="mt-4">
                <Slider posts={featuredSuhbahs.slice(0, MAX_FEATURED_POSTS)}></Slider>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="mt-12">
            <SliderHeader
              text="Les ṣuḥbah récentes :"
              buttonText="Toutes les ṣuḥbah"
              buttonSize="xlarge"
              color="yellow"
              onSeeMoreClick={handleAllRecent}
            ></SliderHeader>
            <div className="mt-4">
              <Slider posts={recentSuhbahs}></Slider>
            </div>
          </div>
        </div>
      ) : (
        <SearchResult
          posts={filteredResults}
          onClearSearch={onClearSearch}
          resultsTitle="Ṣuḥbah trouvées :"
        ></SearchResult>
      )}
    </Layout>
  )
}
export default SuhbahsPage
